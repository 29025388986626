import './materialButton.css'

const MaterialButton = ({ color = 'blue', text = 'button', disabled = false, onClick }) => {

  // colors: blue, green, black, pink, disabled

  return (
    <button type="button" className={`bg-${ disabled ? 'disabled' : color } waves`} onClick={onClick}>{text}</button>
  )
};

export default MaterialButton;