import './otpForm.css';
import { useState, useMemo } from 'react';
import { MaterialButton } from '../../components';
import axios from 'axios';
export const RE_DIGIT = new RegExp(/^\d+$/);



const OtpInput = ({ valueLength = 6, setStatus, setMessage, qrHash, formMsg }) => {
  const [otp, setOtp] = useState('');
  const onChange = (value) => setOtp(value);

  const valueItems = useMemo(() => {
    const valueArray = otp.split('');
    const items = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }, [otp, valueLength]);



  const focusToNextInput = (target) => {
    const nextElementSibling = target.nextElementSibling;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };
  const focusToPrevInput = (target) => {
    const previousElementSibling = target.previousElementSibling;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const inputOnKeyDown = ({ e, index }) => {
    const {
      target = {},
      key = ''
    } = e;
    const {
      value: targetValue = {}
    } = target


    target.setSelectionRange(0, targetValue.length);

    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault();
      return focusToPrevInput(target);
    }
    if (key === 'Backspace') {
      e.preventDefault();
      if (otp.length - 1 > 0) {
        const newValue = otp.substring(0, index === otp.length - 1 ? otp.length - 1 : index);
        onChange(newValue);
      } else {
        onChange('')
      }
      return focusToPrevInput(target);
    }

    if (key !== 'Backspace' || targetValue !== '') {
      return;
    }

    focusToPrevInput(target);
  };

  const inputOnFocus = (e) => {
    const { target } = e;

    const prevInputEl =
      target.previousElementSibling;

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };


  const inputOnChange = (e, index) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);
    const nextInputEl = target.nextElementSibling
    const targetValueLength = targetValue.length;


    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    if (targetValueLength === 1) {
      const newValue = otp.substring(0, index) + targetValue;

      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }
      focusToNextInput(target);
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);

      target.blur();
    }
  };





  return (
    <div className="otp-container">
      <h1>Please enter the One-Time Password to redeem the offer</h1>
      <p>{formMsg}</p>
      <div className="otp-group">
        {valueItems.map((digit, index) => (
          <input
            key={index}
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            pattern="\d{1}"
            maxLength={valueLength}
            className="otp-input"
            value={digit}
            onChange={(e) => inputOnChange(e, index)}
            onKeyDown={(e) => inputOnKeyDown({ e, index })}
            onFocus={inputOnFocus}
          />
        ))}
      </div>
      <MaterialButton color={'pink'} text={'confirm'} disabled={otp.length < 6} onClick={async () => {
        try {
          const result = await axios.post(`${process.env.REACT_APP_CORE_API_BASE_URL}/merchant/confirm-otp`, {
            qrHash,
            otp
          });
          if (result?.data?.msg) {
            setMessage(result?.data?.msg);
          }
          setStatus('verified');
          // console.log('result', result.data);
        } catch (e) {
          setStatus('error');
          if (e?.response?.data?.msg){
            setMessage(e?.response?.data?.msg);
          }
          console.error('error', e?.response?.data);
        }
      }} />
      {/* <MaterialButton color={'pink'} text={'confirm'} disabled={otp.length < 6} onClick={async () => {
        try {
          const result = await axios.post(`${process.env.REACT_APP_CORE_API_BASE_URL}/merchant/resend-otp`, {
            qrHash,
            otp
          });
          if (result?.data?.msg) {
            setMessage(result?.data?.msg);
          }
          setStatus('verified');
          // console.log('result', result.data);
        } catch (e) {
          setStatus('error');
          if (e?.response?.data?.msg){
            setMessage(e?.response?.data?.msg);
          }
          console.error('error', e?.response?.data);
        }
      }} /> */}
    </div>
  );
}

export default OtpInput;
